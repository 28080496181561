import request from '@/utils/request'

// 查询所有焦点图
export const getAllSwiper = () => {
  return request({
    method: 'GET',
    url: '/v2/api/swiper/list',
    params: {
      status: 1
    }
  })
}

// 查询分类
export const getAllCategory = () => {
  return request({
    method: 'GET',
    url: '/v2/category/getMiniCategoryList',
    params: {
      status: 1,
      type: 0
    }
  })
}

// 查询推荐数据
export const getRecomList = ({ recommend, pagesize, random = '', cateId = '' }) => {
  return request({
    method: 'GET',
    url: '/v2/course/getAllCourse',
    params: {
      status: 1,
      pagesize,
      recommend,
      random,
      cate_id: cateId
    }
  })
}

// 根据分类查询数据
export const getCoursesByCateList = () => {
  return request({
    method: 'GET',
    url: '/v2/category/getCoursesByCateList',
    params: {
      status: 1,
      pagesize: 30,
      type: 'all'
    }
  })
}

// 查询分类数据
export const getSubCategorty = (id) => {
  return request({
    url: `/v2/category/getSubCategorty/${id}`,
    method: 'GET',
    params: {
      status: 1
    }
  })
}

// 查询分类详情
export const categoryDetail = (id) => {
  return request({
    url: `/v2/category/category_detail/${id}`,
    method: 'GET',
    params: {
      status: 1
    }
  })
}

export const getCourseByCate = (params) => {
  return request({
    url: `/v2/course/getCourseByCate/${params.id}`,
    method: 'GET',
    params: { ...params, status: 1 }
  })
}
